import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import _get from 'lodash/get'
import AlertTriangle from 'react-feather/dist/icons/alert-triangle'
import Layout from '../components/StructureComponents/Layout'
import Header from '../components/shared/Header'
import FooterSection from '../components/PageComponents/FooterSection'

export default ({ data }) => {
  const { footersection, herosection } = data.page.frontmatter
  return (
    <Layout>
      <Header />
      <Helmet>
        <title>404 – Page Not Found</title>
      </Helmet>
      <section className="section thick">
        <div className="container skinny taCenter">
          <div className="content">
            <h1 className="title">{herosection.title} </h1>
            <p className="subTitle">{herosection.subTitle}</p>
            <p className="description">{herosection.description}</p>
            <p className="linksTitle">{herosection.linksTitle}:</p>
            <div className="links_div">
              {herosection.btnList.map((btn, index) => (
                <a key={index} href={btn.link} className="links">
                  {btn.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
      <FooterSection {...footersection} />
    </Layout>
  )
}
export const pageQuery = graphql`
  ## Query for PageNOtFoundQuery data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  {
    page: markdownRemark(fields: { contentType: { eq: "404" } }) {
      frontmatter {
        meta {
          title
          description
          ogimage
          canonicalLink
        }
        herosection {
          title
          subTitle
          description
          linksTitle
          btnList {
            title
            link
          }
        }
        footersection {
          primaryHeading
          description
          secondaryheading
          tertiaryheading
          footerList {
            primaryheading
            footerLink
          }
        }
      }
    }
  }
`
